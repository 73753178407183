import React from "react";
import {graphql} from "gatsby";
import CategoryLayout from "../../components/tronpedia/category-layout";
import {Helmet} from "react-helmet";
import blockchainBull from "../../images/tronpedia/topic-pages/blockchain-bull.png";

const Blockchain = ({data, location}) => {

    const pageData = {
        title: "Blockchain",
        description: "Stability… in crypto?? This section covers stablecoins, their importance in the digital landscape, and the biggest providers of them. ",
        img: blockchainBull,
    }


    return (
        <>
            <Helmet>
                <title>TRONpedia Blockchain | TRONDAO</title>
                <meta name="description"
                      content="Ready to explore blockchain? This section is packed full of useful information, whether you're a newcomer or an expert looking to learn more."/>
                <meta property="og:description"
                      content="Ready to explore blockchain? This section is packed full of useful information, whether you're a newcomer or an expert looking to learn more."/>
                <meta property="og:title" content="TRONpedia Blockchain | TRONDAO"/>
            </Helmet>
            <CategoryLayout data={data} location={location} pageData={pageData}/>
        </>
    );
};


export default Blockchain;

export const pageQuery = graphql`
query {
   allWpTronpediaArticle (
    filter: {tronpedia_article_info: {category: {eq: "BLOCKCHAIN"}}}
  ) {  
      edges {
         node {
            slug
        title
        tronpedia_article_info {
           category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
   }
}

`
